<template>
  <q-page v-if="dataService" class="main-wrapper-page single-page" style="background-color: var(--q-accent);">
    <div class="row no-wrap">
      <div class="col col-2 q-px-md">
        <div class="left-sidebar">
          <infra-service-logo v-if="dataService.icon && dataService.icon !== 'default'" :logo="dataService.icon" style="width: 120px"/>
          <span v-else class="icon-service q-mb-lg" :class="[`${dataService.cloudType ? dataService.cloudType.toLowerCase() : 'na'}`, { tool : dataService.type === 'Tool'}]">
            <q-icon name="settings" color="white" size="80px"></q-icon>
          </span>
        </div>
        <ul class="section-titles q-px-none q-py-none q-mx-none q-my-none">
          <li @click="scrollToItem('generalInfo')">General Info</li>
          <li @click="scrollToItem('contacts')">Contacts</li>
          <li @click="scrollToItem('links')">Links</li>
          <li v-if="dataService.templates && dataService.templates.length" @click="scrollToItem('templates')">Part of template</li>
          <li v-if="dataService.dependencies && dataService.dependencies.length" @click="scrollToItem('dependencies')">Dependencies</li>
          <li @click="scrollToItem('onboarded')">Onboarded on</li>
          <li @click="scrollToItem('adrs')">ADRs</li>
          <li @click="scrollToItem('details')">Details</li>
        </ul>
      </div>
      <div class="col col-7 q-px-md">
        <q-scroll-area
          style="width: 100%; padding-right: 10px"
          :style="`height: calc(100vh - ${currentBanner ? '195px' : '140px'})`"
          :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '3px', opacity: 1, right: 0, }"
        >
          <div class="service-section q-mb-md q-px-lg q-py-lg bg-white">
            <div class="flex items-center justify-between">
              <h5 class="q-mt-none q-mb-sm" ref="generalInfo">General Info</h5>
              <q-btn v-if="canBeEdited" @click="showEditDescriptionDialog" style="margin-bottom: 15px" icon="edit" flat round size="12px">
                <q-tooltip>Edit info section</q-tooltip>
              </q-btn>
            </div>
            <div v-if="dataService.description && dataService.description.trim().length" class="single-page-markdown" v-html="dataMarkdown(dataService.description)"></div>
            <div v-else class="no-content flex column items-center">
              <q-icon name="do_disturb_alt" size="90px" color="primary"></q-icon>
              Temporary Unavailable
            </div>
          </div>
          <div class="service-section q-mb-md q-px-lg q-py-lg bg-white">
            <div class="flex items-center justify-between">
              <h5 class="q-mt-none q-mb-sm" ref="contacts">Contacts</h5>
              <q-btn v-if="canBeEdited" @click="showEditContactsDialog" style="margin-bottom: 15px" icon="edit" flat round size="12px">
                <q-tooltip>Edit contacts section</q-tooltip>
              </q-btn>
            </div>
            <ul v-if="dataService.contacts && dataService.contacts.length" class="links q-px-none q-py-none q-mx-none q-my-none">
              <li v-for="(contact, i) in dataService.contacts" :key="i" class="q-mb-sm">
                <span style="text-transform: capitalize" class="contact-label">{{contact.label}}</span>
                <span style="text-transform: none">{{ contact.contact }}</span>
              </li>
            </ul>
            <div v-else class="no-content flex column items-center">
              <q-icon name="do_disturb_alt" size="90px" color="primary"></q-icon>
              Temporary Unavailable
            </div>
          </div>
          <div class="service-section q-mb-md q-px-lg q-py-lg bg-white">
            <div class="flex items-center justify-between">
              <h5 class="q-mt-none q-mb-sm" ref="links">Links</h5>
              <q-btn v-if="canBeEdited" @click="showEditLinksDialog" style="margin-bottom: 15px" icon="edit" flat round size="12px">
                <q-tooltip>Edit links section</q-tooltip>
              </q-btn>
            </div>
            <ul v-if="dataService.links && dataService.links.length" class="links q-px-none q-py-none q-mx-none q-my-none">
              <li v-for="link in dataService.links" :key="link.id" class="q-mb-sm">
                <a :href="link.url" target="_blank">
                  <q-icon name="mdi-link-variant" class="q-mr-xs" size="xs" color="primary"></q-icon>
                  {{link.label || link.url }}
                </a>
              </li>
            </ul>
            <div v-if="dataService.standards && dataService.standards.length">
              <span class="block text-uppercase q-mb-sm q-mt-md" style="font-size: 10px; font-weight: bold; color: #979ba4; letter-spacing: 1px;">Relevant Standards</span>
              <ul class="links q-px-none q-py-none q-mx-none q-my-none">
                <li v-for="standard in dataService.standards" :key="standard.id" class="q-mb-sm">
                  <a :href="standard.standardLink" target="_blank">
                    <q-icon name="engp:engp-ico-standards" class="q-mr-xs" size="16px" color="primary"></q-icon>
                    {{standard.standardName}}
                  </a>
                </li>
              </ul>
            </div>
            <div v-if="!(dataService.links && dataService.links.length || dataService.standards && dataService.standards.length)" class="no-content flex column items-center">
              <q-icon name="do_disturb_alt" size="90px" color="primary"></q-icon>
              Temporary Unavailable
            </div>
          </div>
          <div v-if="dataService.templates && dataService.templates.length" class="service-section q-mb-md q-px-lg q-py-lg bg-white">
            <h5 class="q-mt-none q-mb-sm" ref="templates">Part of template</h5>
            <ul class="list q-px-none q-py-none q-mx-none q-my-none">
              <li v-for="(template, key) in dataService.templates" :key="key" class="q-mb-sm">
                <q-icon name="event_note" class="q-mr-xs" size="xs" color="primary"></q-icon>
                {{ template.name }}
              </li>
            </ul>
          </div>
          <div v-if="dataService.dependencies && dataService.dependencies.length" class="service-section q-mb-md q-px-lg q-py-lg bg-white">
            <h5 class="q-mt-none q-mb-sm" ref="dependencies">Dependencies</h5>
            <ul class="list dependencies q-px-none q-py-none q-mx-none q-my-none">
              <li v-for="service in dataService.dependencies" :key="service.id" class="q-mb-sm">
                <q-icon :name="service.icon ? service.icon : 'settings'" color="primary" size="md" class="q-mr-sm"></q-icon>
                <router-link :to="`/infra-services/${service.id}`" target="_blank">{{service.displayName || service.serviceName || service.apmid}}</router-link>
              </li>
            </ul>
          </div>
          <div class="service-section q-px-lg q-mb-md q-py-lg bg-white">
            <h5 class="q-mt-none q-mb-sm" ref="onboarded">Onboarded on {{dataService?.registrations.length}} Digital Services</h5>
            <div v-if="showingDs?.length" class="row q-col-gutter-xs">
              <router-link :to="`/my-portfolio/${registration.digitalService.apmId}`" v-for="(registration, key) in showingDs" :key="key" class="col-6 q-px-xs q-py-xs">
                {{registration.digitalService.digitalServiceName}}
              </router-link>
              <div class="flex flex-center full-width q-mt-md">
                <q-pagination :max="dsPages" boundary-numbers :max-pages="4" direction-links v-model="dsPage"></q-pagination>
              </div>
            </div>
            <div v-else class="no-content flex column items-center">
              <q-icon name="do_disturb_alt" size="90px" color="primary"></q-icon>
              No data
            </div>
          </div>
          <div class="service-section q-px-lg q-py-lg bg-white q-mb-md">
            <h5 class="q-mt-none q-mb-sm" ref="adrs">ADRs</h5>
            <div v-if="dataService?.adrs?.length" class="row q-col-gutter-xs adrs-holder">
              <div v-for="adr in dataService.adrs" :key="adr.id" @click="goToAdr(adr.id)" class="col-6 q-px-xs q-py-xs adr-holder">
                <div class="adr">
                  <span class="adr-title">{{adr.name}}</span>
                  <span class="scope" style="color: #009688; font-size: 14px">For {{adr.scope?.units}}, {{adr.scope?.regions}}</span>
                  <div class="adr-content service-description-markdown" v-html="dataMarkdown(adr.requirements || adr.content)"></div>
                  <span class="block"><b>Valid date:</b> {{adr.validDate}}</span>
                </div>
              </div>
            </div>
            <div v-else class="no-content flex column items-center">
              <q-icon name="do_disturb_alt" size="90px" color="primary"></q-icon>
              No data
            </div>
          </div>
        
        <div class="service-section q-mb-md q-px-lg q-py-lg bg-white">
          <div class="flex items-center justify-between">
            <h5 class="q-mt-none q-mb-sm" ref="details">Lifecycle stage</h5>
            <q-btn v-if="canBeEdited" @click="showEditLifecycleDialog" style="margin-bottom: 15px" icon="edit" flat round size="12px">
              <q-tooltip>Set lifecycle stage</q-tooltip>
            </q-btn>
          </div>
          <div v-if="dataService.state">
            {{ dataService.state }}
          </div>
          <div v-else class="no-content flex column items-center">
            <q-icon name="do_disturb_alt" size="90px" color="primary"></q-icon>
            Temporary Unavailable
          </div>
        </div>

        <div class="service-section q-mb-md q-px-lg q-py-lg bg-white">
          <div class="flex items-center justify-between">
            <h5 class="q-mt-none q-mb-sm" >Service type</h5>
            <q-btn v-if="canBeEdited" @click="showEditServiceTypeDialog" style="margin-bottom: 15px" icon="edit" flat round size="12px">
              <q-tooltip>Set service type</q-tooltip>
            </q-btn>
          </div>
          <div v-if="dataService.type">
            {{ dataService.type }}
          </div>
          <div v-else class="no-content flex column items-center">
            <q-icon name="do_disturb_alt" size="90px" color="primary"></q-icon>
            Temporary Unavailable
          </div>
        </div>

        <div class="service-section q-mb-md q-px-lg q-py-lg bg-white">
          <div class="flex items-center justify-between">
            <h5 class="q-mt-none q-mb-sm" >Cloud type</h5>
            <q-btn v-if="canBeEdited" @click="showEditCloudTypeDialog" style="margin-bottom: 15px" icon="edit" flat round size="12px">
              <q-tooltip>Set cloud type</q-tooltip>
            </q-btn>
          </div>
          <div v-if="dataService.cloudType">
            {{ dataService.cloudType }}
          </div>
          <div v-else class="no-content flex column items-center">
            <q-icon name="do_disturb_alt" size="90px" color="primary"></q-icon>
            Temporary Unavailable
          </div>
        </div>

        <div class="service-section q-px-lg q-px-lg q-py-lg bg-white">
            <div class="flex items-center justify-between">
              <h5 class="q-mt-none q-mb-sm">Category</h5>
              <q-btn v-if="canBeEdited" @click="showEditCategoryDialog" style="margin-bottom: 15px" icon="edit" flat round size="12px">
                <q-tooltip>Set category</q-tooltip>
              </q-btn>
            </div>
            <div v-if="dataService.categoryName">
              {{ dataService.categoryName }}
            </div>
            <div v-else class="no-content flex column items-center">
              <q-icon name="do_disturb_alt" size="90px" color="primary"></q-icon>
              Temporary Unavailable
            </div>
          </div>
        </q-scroll-area>
      </div>
      <div class="col col-3 q-px-md">
        <q-btn
          v-if="dataService.orderable"
          class="service-btn q-mx-none q-py-md q-mb-md"
          unelevated
          :disable="notCanBeDeleted || uiConfig.disableEditServices.includes(dataService?.apmid) || uiConfig.hideServices.includes(dataService?.apmid)"
          color="primary"
          @click="addToServiceDesign"
          @mouseover="showRemove = true"
          @mouseout="showRemove = false"
        >
          <q-icon :name="notCanBeDeleted ? 'lock' : isInDesign && showRemove ? 'remove' : isInDesign ? 'done' : 'control_point'" color="white" size="lg"></q-icon>
          {{ notCanBeDeleted ? 'Can not be removed' : isInDesign && showRemove ? 'Remove' : isInDesign ? 'Added to my Digital Service' : 'Add to my digital service' }}
        </q-btn>
        <q-btn
          class="service-btn support-btn q-mx-none q-py-md"
          unelevated
          outline
          flat
          text-color="text-color"
          @click="showFeedbackDialog"
        >
          <q-icon name="chat_bubble_outline" color="text-color" size="lg"></q-icon>
          Help us improve
        </q-btn>
      </div>
    </div>
  </q-page>
  <feedback-dialog
    @sendFeedback="createFeedback($event)"
    :feedbacks="dataService?.feedbacks || []"
    :show="feedbackDialog"
    :loading="loadFeedback"
    :service-name="dataService?.serviceName || dataService?.displayName || dataService?.apmid"
    @close="feedbackDialog = false"
  ></feedback-dialog>
  <q-dialog v-model="showEditContacts" persistent>
    <q-card style="min-width: 780px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Edit contacts</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="showEditContacts = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <q-form ref="infraServiceForm">
          <div class="row q-col-gutter-sm q-mb-sm" v-for="(contact, key) in contacts" :key="key">
            <div class="col-5">
              <q-input v-model="contacts[key].label" :rules="[(val) => !!val || 'Field is required', (val) => val.length < 40 || 'Max length is 40 symbols']" outlined label="Position"></q-input>
            </div>
            <div class="col-6">
              <q-input v-model="contacts[key].contact" :rules="emailRules" outlined label="Email"></q-input>
            </div>
            <div class="col-1 text-center">
              <q-btn
                flat
                icon="remove"
                color="white"
                text-color="black"
                small
                class="q-px-none q-mt-md btn--no-hover"
                :ripple="false"
                size="11px"
                @click="removeContact(key)"
              >
              </q-btn>
            </div>
          </div>
          <q-btn
            flat
            icon="add"
            color="white"
            text-color="black"
            small
            class="q-px-none q-mt-md btn--no-hover"
            :ripple="false"
            size="11px"
            label="Add contact"
            @click="addContact"
          >
          </q-btn>
        </q-form>
      </q-card-section>
      <q-card-actions class="q-px-md q-mt-lg q-pt-none q-pb-lg">
        <q-btn style="border-radius: 10px; text-transform: capitalize" color="primary" text-color="text-color" @click="updateInfraservice">Submit</q-btn>
        <q-btn style="border-radius: 10px; text-transform: capitalize" class="btn--no-hover" flat @click="showEditContacts = false">Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="showEditLinks" persistent>
    <q-card style="min-width: 780px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Edit links</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="showEditLinks = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <q-form ref="infraServiceForm">
          <div class="row q-col-gutter-sm q-mb-sm" v-for="(link, key) in links" :key="key">
            <div class="col-5">
              <q-input v-model="links[key].label" :rules="[(val) => !!val || 'Field is required', (val) => val.length < 40 || 'Max length is 40 symbols']" outlined label="Label"></q-input>
            </div>
            <div class="col-6">
              <q-input v-model="links[key].url" :rules="[(val) => !!val || 'Field is required']" outlined label="URL"></q-input>
            </div>
            <div class="col-1 text-center">
              <q-btn
                flat
                icon="remove"
                color="white"
                text-color="black"
                small
                class="q-px-none q-mt-md btn--no-hover"
                :ripple="false"
                size="11px"
                @click="removeLink(key)"
              >
              </q-btn>
            </div>
          </div>
          <q-btn
            flat
            icon="add"
            color="white"
            text-color="black"
            small
            class="q-px-none q-mt-md btn--no-hover"
            :ripple="false"
            size="11px"
            label="Add link"
            @click="addLink"
          >
          </q-btn>
        </q-form>
      </q-card-section>
      <q-card-actions class="q-px-md q-mt-lg q-pt-none q-pb-lg">
        <q-btn style="border-radius: 10px; text-transform: capitalize" color="primary" text-color="text-color" @click="updateInfraservice">Submit</q-btn>
        <q-btn style="border-radius: 10px; text-transform: capitalize" class="btn--no-hover" flat @click="showEditLinks = false">Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="showEditName" persistent>
    <q-card style="min-width: 780px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Edit Display name</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="showEditName = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <q-form ref="infraServiceForm">
          <q-input v-model="displayName" :rules="[(val) => !!val || 'Field is required', (val) => val.length < 100 || 'Max length is 100 symbols']" outlined label="Display name"></q-input>
        </q-form>
      </q-card-section>
      <q-card-actions class="q-px-md q-mt-lg q-pt-none q-pb-lg">
        <q-btn style="border-radius: 10px; text-transform: capitalize" color="primary" text-color="text-color" @click="updateInfraservice">Submit</q-btn>
        <q-btn style="border-radius: 10px; text-transform: capitalize" class="btn--no-hover" flat @click="showEditName = false">Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="showEditDescription" persistent>
    <q-card style="min-width: 1024px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Edit description</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="showEditDescription = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <q-form ref="infraServiceForm">
          <div class="row q-col-gutter-md">
          <div class="col-6">
            <q-scroll-area
              :style="{height: widgetHeight}"
              :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '2px', opacity: 1, right: 0, }"
            >
              <q-input type="textarea" ref="inputDescription" :rules="[(val) => !!val || 'Field is required']" hint="Please, use markdown" v-model="description" outlined></q-input>
            </q-scroll-area>
          </div>
          <div class="col-6">
            <q-scroll-area
              :style="{height: widgetHeight}"
              :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '2px', opacity: 1, right: 0, }"
            >
              <q-resize-observer @resize="onResize"></q-resize-observer>
              <div class="single-page-markdown" v-html="dataMarkdown(description)"></div>
            </q-scroll-area>
          </div>
        </div>
        </q-form>
      </q-card-section>
      <q-card-actions class="q-px-md q-mt-lg q-pt-none q-pb-lg">
        <q-btn style="border-radius: 10px; text-transform: capitalize" color="primary" text-color="text-color" @click="updateInfraservice">Submit</q-btn>
        <q-btn style="border-radius: 10px; text-transform: capitalize" class="btn--no-hover" flat @click="showEditDescription = false">Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showEditLifecycle" persistent>
    <q-card style="min-width: 1024px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Edit lifecycle stage</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="showEditLifecycle = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <q-form ref="infraServiceForm">
          <q-select
            v-model="lifecycleStage"
            option-value="value"
            :rules="[(val) => !!val || 'Field is required']"
            map-options
            option-label="label"
            dense bg-color="white"
            options-dense
            outlined
            label="Lifecycle stage*"
            emit-value
            :options="lifecycleStages"
          />
        </q-form>
      </q-card-section>
      <q-card-actions class="q-px-md q-mt-lg q-pt-none q-pb-lg">
        <q-btn style="border-radius: 10px; text-transform: capitalize" color="primary" text-color="text-color" @click="updateInfraservice">Submit</q-btn>
        <q-btn style="border-radius: 10px; text-transform: capitalize" class="btn--no-hover" flat @click="showEditLifecycle = false">Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  
  <q-dialog v-model="showEditServiceType" persistent>
    <q-card style="min-width: 1024px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Edit service type</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="showEditServiceType = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <q-form ref="infraServiceForm">
          <q-select
            v-model="serviceType"
            option-value="value"
            :rules="[(val) => !!val || 'Field is required']"
            map-options
            option-label="label"
            dense bg-color="white"
            options-dense
            outlined
            label="Service type*"
            emit-value
            :options="serviceTypes"
          />
        </q-form>
      </q-card-section>
      <q-card-actions class="q-px-md q-mt-lg q-pt-none q-pb-lg">
        <q-btn style="border-radius: 10px; text-transform: capitalize" color="primary" text-color="text-color" @click="updateInfraservice">Submit</q-btn>
        <q-btn style="border-radius: 10px; text-transform: capitalize" class="btn--no-hover" flat @click="showEditServiceType = false">Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showEditCategory" persistent>
    <q-card style="min-width: 1024px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Edit description</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="showEditCategory = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <q-form ref="infraServiceForm">
          <q-select
            v-model="category"
            option-value="value"
            :rules="[(val) => !!val || 'Field is required']"
            map-options
            option-label="label"
            dense bg-color="white"
            options-dense
            outlined
            label="Category *"
            emit-value
            :options="categories"
          />
        </q-form>
      </q-card-section>
      <q-card-actions class="q-px-md q-mt-lg q-pt-none q-pb-lg">
        <q-btn style="border-radius: 10px; text-transform: capitalize" color="primary" text-color="text-color" @click="updateInfraservice">Submit</q-btn>
        <q-btn style="border-radius: 10px; text-transform: capitalize" class="btn--no-hover" flat @click="showEditCategory = false">Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showEditCloudType" persistent>
    <q-card style="min-width: 1024px; border-radius: 0px;">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Edit clod type</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="showEditCloudType = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <q-form ref="infraServiceForm">
          <q-select
            v-model="cloudType"
            option-value="value"
            :rules="[(val) => !!val || 'Field is required']"
            map-options
            option-label="label"
            dense bg-color="white"
            options-dense
            outlined
            label="Cloud type *"
            emit-value
            :options="cloudTypes"
          />
        </q-form>
      </q-card-section>
      <q-card-actions class="q-px-md q-mt-lg q-pt-none q-pb-lg">
        <q-btn style="border-radius: 10px; text-transform: capitalize" color="primary" text-color="text-color" @click="updateInfraservice">Submit</q-btn>
        <q-btn style="border-radius: 10px; text-transform: capitalize" class="btn--no-hover" flat @click="showEditCloudType = false">Cancel</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import apollo from "@/library/http/apollo";
  import gql from "graphql-tag";
  import {marked} from "marked";
  import { v4 as uuidv4 } from 'uuid';
  import emitter from "tiny-emitter/instance";
  import { scroll } from 'quasar';
  import {SERVICE_DESIGN_ACTIONS, SERVICE_DESIGN_GETTERS} from "@/store/modules/service-design";
  import { APP_ACTIONS, APP_GETTERS } from "@/store/modules/app";
  import InfraServiceLogo from "@/shared/components/InfraServiceLogo.vue";
  import {BANNERS_GETTERS} from "@/store/modules/banners";
  import { SERVICES_ACTIONS, SERVICES_GETTERS } from "@/store/modules/services";
  import FeedbackDialog from "@/shared/components/FeedbackDialog";
  import { getUserBatchPermissions } from "@/pages/digitalService/api";
  import * as uiConfig from "@/shared/ui-config.json";

  const { getScrollTarget, setVerticalScrollPosition } = scroll;

  export default {
    name: "InfraService",
    components: {
      FeedbackDialog,
      InfraServiceLogo
    },
    data() {
      return {
        showRemove: false,
        dataService: undefined,
        breadcrumbs: [],
        feedbackDialog: false,
        loadFeedback: false,
        showEditContacts: false,
        showEditLinks: false,
        showEditDescription: false,
        showEditCategory: false,
        showEditServiceType: false,
        showEditCloudType: false,
        showEditLifecycle: false,
        contacts: [],
        links: [],
        description: null,
        displayName: null,
        showEditName: false,
        widgetHeight: '200px',
        dsPage: 1,
        showDsCount: 8,
        uiConfig: {},
        categories: [],
        category: {},
        cloudType: null,
        serviceType: null,
        lifecycleStage: null,
        cloudTypes: [
         'Private',
         'Public',
         'Onprem',
         'N/A',
        ],
        serviceTypes: [
         'Shared Service',
         'Certified Product',
         'Stratum',
         'Vanilla',
         'Tool',
         'N/A',
        ],
        lifecycleStages: [
         'Deprecated',
         'Planned',
         'Production',
         'Unsupported',
        ],
      }
    },
    computed: {
      ...mapGetters('serviceDesign', {
        design: SERVICE_DESIGN_GETTERS.ALL
      }),
      ...mapGetters('banners', {
        currentBanner: BANNERS_GETTERS.CURRENT
      }),
      ...mapGetters('app', {
        userInfo: APP_GETTERS.USER_INFO,
        infraServiceAuthSettings: APP_GETTERS.INFRA_SERVICE_AUTH_SETTINGS
      }),
      ...mapGetters('services', {
        catalogServices: SERVICES_GETTERS.ALL,
      }),
      dsPages() {
        return Math.ceil(this.dataService?.registrations?.length/this.showDsCount)
      },
      showingDs() {
        return JSON.parse(JSON.stringify(this.dataService?.registrations)).splice((this.dsPage -1) * this.showDsCount, this.showDsCount)
      },
      emailRules() {
        const pattern = new RegExp("^\\S+@\\S+\\.\\S+$");
        return [
          (val) => !!val || 'Field is required',
          (val) => pattern.test(val) || 'Invalid email'
        ]
      },
      canBeEdited() {
        return true
       //return this.infraServiceAuthSettings.owners.includes(this.userInfo.email) || this.infraServiceAuthSettings.isEditGroup
      },
      isInDesign() {
        const category = this.design.find(category => category.categoryId === this.dataService.categoryId);
        return category ? (category.infraServices.some(infra => infra.apmId === this.dataService.apmId)) : false;
      },
      notCanBeDeleted() {
        const category = this.design.find(category => category.categoryId === this.dataService.categoryId);
        return category ? category.infraServices.some(infra => infra.apmid === this.dataService.apmid && infra.notCanBeDeleted) : false;
      },
    },
    methods: {
      ...mapActions('serviceDesign', {
        setInfraService: SERVICE_DESIGN_ACTIONS.SET_INFRASERVICE,
        removeInfraService: SERVICE_DESIGN_ACTIONS.REMOVE_INFRASERVICE
      }),
      ...mapActions('services', {
        getCatalog: SERVICES_ACTIONS.GET_SERVICES,
      }),
      ...mapActions('app', {
        setPageTitle: APP_ACTIONS.SET_PAGE_TITLE,
        setEditGroup: APP_ACTIONS.SET_INFRA_SERVICE_EDIT_GROUP,
      }),
      onResize(size) {
        if (size.height > 600) {
          this.widgetHeight = '600px';
          this.$refs.inputDescription.$el.control.style.height = `560px`
        }
      },
      async updateInfraservice() {
      const updateData = {
          contacts: this.contacts.length ? this.contacts : null,
          links: this.links.length ? this.links : null,
          description: this.description,
          displayName: this.displayName,
          infraServiceId: this.$route.params.id,
          cloudType: this.cloudType,
          serviceType: this.serviceType,
          lifecycleStage: this.lifecycleStage,
        };

        // Hot fix. Category migth be an empty object;
        if(typeof this.category === 'string') {
          updateData.categoryId = this.category;
        }
                
        this.$refs.infraServiceForm.validate().then( async (valid) => {
          if (valid) {
            await apollo.apolloClient.mutate({
              mutation: gql`
                mutation updateInfraService(
                    $data: FrontendInfraserviceInput!
                ) {
                  updateInfraService(
                    data: $data,
                  )
                }
              `,
              variables: {
                data: updateData
              }
            }).then(async () => {
              this.$notify('positive', 'Success', `Infraservice was updated successfully.`);
              await this.reset();
              await this.getServiceData(this.$route.params.id);
            }).catch(error => {
              this.$notify('negative', 'Error', error.message);
            });
          }
        });
      },
      async reset() {
        this.showEditDescription = false;
        this.showEditName = false;
        this.showEditLinks = false;
        this.showEditContacts = false;
        this.showEditCategory = false;
        this.showEditCloudType = false;
        this.showEditServiceType = false;
        this.showEditLifecycle = false;
      },
      addContact() {
        this.contacts.push({label: '', contact: ''})
      },
      removeContact(index) {
        this.contacts.splice(index, 1)
      },
      addLink() {
        this.links.push({label: '', url: '', description: '', id: uuidv4()})
      },
      removeLink(index) {
        this.links.splice(index, 1)
      },
      showEditContactsDialog() {
        this.showEditContacts = true;
        this.contacts = JSON.parse(JSON.stringify(this.dataService.contacts));
      },
      showEditLinksDialog() {
        this.showEditLinks = true;
        this.links = JSON.parse(JSON.stringify(this.dataService.links));
      },
      showEditDisplayNameDialog() {
        this.showEditName = true;
        this.displayName = this.dataService.displayName;
      },
      showEditDescriptionDialog() {
        this.showEditDescription = true;
        this.description = JSON.parse(JSON.stringify(this.dataService.description));
      },
      
      showEditCategoryDialog() {
        console.log('showEditCategoryDialog')
        this.categories = this.catalogServices.map(cat => {
          const { name, categoryId } = cat;
          return { label: name, value: cat.id, id: cat.id };
        });
        this.showEditCategory = true;
        this.category.label = this.dataService.categoryName
        this.category.value = this.dataService.categoryId;
        this.category.id = this.dataService.id;

        console.log('this.category', this.category)
      },

      showEditCloudTypeDialog() {
        this.showEditCloudType = true;
        this.cloudType = this.dataService.cloudType;
      },
      
      showEditServiceTypeDialog(){
        this.showEditServiceType = true;
        this.serviceType = this.dataService.type;
      },

      showEditLifecycleDialog() {
        this.showEditLifecycle = true;
        this.lifecycleStage = this.dataService.state;
      },
      async createFeedback(feedback) {
        this.loadFeedback = true;
        await apollo.apolloClient.mutate({
          mutation: gql`
            mutation createFeedback(
                $infraServiceId: String,
                $feedback: FrontendFeedbackInput!
            ) {
              createFeedback(
                feedback: $feedback,
                infraServiceId: $infraServiceId
              )
            }
          `,
          variables: {
            feedback: feedback,
            infraServiceId: this.dataService.serviceId
          }
        }).then((res) => {
          if (res.data.createFeedback) {
            this.$notify('positive', 'Success', `Feedback for ${this.dataService.displayName} was sent successfully.`);
          }
        }).catch(error => {
          this.$notify('negative', 'Error', error.message);
        }).finally(async () => {
          this.loadFeedback = false;
          this.feedbackDialog = false;
          await this.getServiceData(this.$route.params.id);
        })
      },
      dataMarkdown(data) {
        return data ? marked(data) : '';
      },
      addToServiceDesign() {
        if (this.isInDesign) {
          this.removeInfraService({category: {categoryId: this.dataService.categoryId}, infraService: this.dataService});
          this.$notify('info', 'Info', `${this.dataService.displayName || this.dataService.serviceName} was removed from your service design. Please choose digital service if you would like to onboard onto it.`);
        } else {
          this.setInfraService({category: {categoryId: this.dataService.categoryId}, infraService: this.dataService});
          this.$notify('info', 'Info', `${this.dataService.displayName || this.dataService.serviceName} was added to your service design. Please choose digital service if you would like to onboard onto it.`);
        }
      },
      async getServiceData(id) {
        this.$showLoading();
        try {
          await apollo.apolloClient.query({
            query: gql`
              query {
                infraServiceById(id: "${id}") {
                  id
                  apmid
                  categoryId
                  categoryName
                  serviceId
                  orderable
                  icon
                  cloudType
                  description
                  displayName
                  parentServiceDisplayName
                  registrations {
                    digitalService {
                      apmId
                      digitalServiceName
                    }
                  }
                  standards {
                    id
                    standardName
                    standardLink
                  }
                  templates {
                    name
                    createdAt
                  }
                  pavedRoad
                  contacts {
                    label
                    contact
                  }
                  serviceName
                  state
                  type
                  links {
                    id
                    label
                    description
                    url
                  }
                  dependencies {
                    id
                    contacts {
                      label
                      contact
                    }
                    apmid
                    categoryId
                    description
                    displayName
                    pavedRoad
                    serviceName
                    state
                    type
                  }
                  adrs {
                    id
                    name
                    scope
                    status
                    validDate
                    version
                    content
                    requirements
                  }
                  feedbacks {
                    id
                    title
                    description
                    isLike
                    stars
                    createdAt
                    createdBy {
                      name
                    }
                  }
                }
              }
            `
          }).then(async (response) => {
            const service = response.data;
            this.dataService = service.infraServiceById;
            const authData = {
              "userEmail": this.userInfo.email,
              "resources": [
                {
                  "scope": {
                    "platformId": response.data.infraServiceById.apmid
                  },
                  "resource": {
                    "type": "management",
                    "name": "service.catalog.manage"
                  }
                }
              ]
            }
            const res = await getUserBatchPermissions(authData);
            const isEditGroup = (res[response.data.infraServiceById.apmid]["service.catalog.manage"]);
            await this.setEditGroup(isEditGroup);
          })
        } catch (error) {
          this.$notify('negative', 'Error', error.message)
        } finally {
          this.$hideLoading();
        }
        if (!this.catalogServices.length) {
          try {
            await this.getCatalog();
          } catch (error) {
            this.$notify('negative', 'Error', error.message);
          }
        }
      },
      scrollToItem(anchor) {
        const element = this.$refs[anchor];
        const target = getScrollTarget(element);
        const offset = element.offsetTop - (this.currentBanner ? 95 : 45);
        const duration = 1000;
        setVerticalScrollPosition(target, offset, duration);
      },
      goToAdr(id) {
        this.$router.push(`/adrs/${id}`);
      },
      showFeedbackDialog() {
        if (this.dataService.type === "Certified Product") {
          window.open('https://swissreesm.service-now.com/contactone?id=sc_cat_item&table=sc_cat_item&sys_id=674a6d331be7319053e740c1b24bcb1c', '_blank');
        } else {
          this.feedbackDialog = true;
        }
      }
    },
    created() {
      emitter.on('showEditInfraServiceName', () => {
        this.showEditDisplayNameDialog();
      });
    },
    async mounted() {
      this.uiConfig = uiConfig[process.env.VUE_APP_ENV];
      if (this.$route.params.currentStep) {
        emitter.emit('setCurrentStep', {categoryId: this.dataService.categoryId, step: this.$route.params.currentStep});
      }
      this.breadcrumbs = this.$route.meta.breadcrumbs;
      const id = this.$route.params.id;
      await this.getServiceData(id);
    }
  }
</script>

<style lang="scss" scoped>
  .icon-service {
    display: inline-block;
    padding: 10px;
    border-radius: 50%;
    background-color: var(--q-primary);
  }
  .section-titles {
    list-style-type: none;
    font-size: 20px;
    font-weight: bold;

    li {
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
  .service-section {
    border-radius: 15px;
  }
  h5 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .links {
    flex-wrap: wrap;
    list-style-type: none;
    -moz-column-count: 2; /* Firefox */
    -webkit-column-count: 2; /* Safari and Chrome */
    column-count: 2;
    margin: 0 -5px;

    li {
      margin: 0 5px !important;
    }

    a {
      display: inline-block;
      text-decoration: none;
      color: $text-color;
    }
  }

  .list {
    list-style-type: none;
    -moz-column-count: 2; /* Firefox */
    -webkit-column-count: 2; /* Safari and Chrome */
    column-count: 2;
  }
  .contact-label {
    display: block;
    color: var(--q-primary);
    font-weight: bold;
  }

  .dependencies {
    a {
      color: $text-color;
    }
  }

  .icon-service {
    &.private {
      background-color: #428ce1;
    }
    &.public {
      background-color: #73e1a5;
    }
    &.onprem {
      background-color: #a88cd1;
    }
    &.na {
      background-color: #b3bb7e;
    }
    &.tool {
      background-color: #bfb9a7;
    }
  }

  .adr {
    height: 200px;
    border: 1px solid $grey-5;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    .adr-title {
      display: block;
      font-size: 18px;
      font-weight: bold;
      white-space: nowrap;
      max-width: calc(100% - 5px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .scope {
      display: block;
      white-space: nowrap;
      max-width: calc(100% - 5px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .adr-content {
      height: 105px;
      overflow: hidden;
      font-size: 12px;
    }

    .metadata {
      white-space: nowrap;
      max-width: calc(100% - 5px);
      overflow: hidden;
      font-weight: bold;
      font-size: 12px;

      span {
        margin-right: 5px;
      }
    }
  }

</style>